import React from 'react'
import tw from 'tailwind.macro'
import styled, { createGlobalStyle, css } from 'styled-components'
import theme from '../../config/theme'
import "../components/layout.css"

import SEO from '../components/seo'
import Logo from '../components/logo'
import { SiteTitle, Title } from '../components/titles'
import SVG from '../components/svg'
import DemoForm from '../components/demo-form'

const GlobalStyles = createGlobalStyle`
  a {
    color: inherit;
  }
`

const Wrapper = styled.div`
  overflow: hidden;
  ul {
    margin-left: 0;
    margin-bottom: 2rem;
  }
  li {
    display: flex;
    align-items: center;
    list-style: none;
    svg {
      display: block;
      margin-right: .8rem;
    }
  }
`

const Box = styled.div`
  ${tw`flex flex-wrap`}
  width: 100%;
  min-height: 100vh;
  margin: auto;
  border-radius: 1rem;
  a {
    display: inline-block;
  }
`

const Column = css`
  ${tw`py-24 px-12 flex-initial`}
  @media (max-width: ${theme.breakpoints[2]}) {
    flex: 0 100%;
  }
`

const ColumnOne = styled.div`
  ${Column};
  ${tw`text-center`};
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 0 40%;
  justify-content: space-between;
  ${Title} {
    margin-top: 2rem;
  }
  svg {
    z-index: -1
  }
`

const ColumnTwo = styled.div`
  ${Column};
  flex: 1;
  ${tw`px-5 md:px-24 lg:px-32 xl:px-48`};
  color: white;
  background: linear-gradient(#633285, #2D0444);
`

const Demostracion = props => (
  <>
  <GlobalStyles/>
  <SEO title="Demostración" description="Prueba Jornada10 - Entra y toquetea todo lo que quieras sin compromiso" />
  <Wrapper>
    <Box>
      <ColumnOne>
        <div>
          <Logo/>
          <SiteTitle>Jornada</SiteTitle>
          <Title mb=".8">Sistema de registro horario</Title>
          <p>Registra el horario de tus trabajadores con tarjetas o llaveros contactless y/o móvil.</p>
        </div>
        <SVG icon="ona" width="80" top="30%"/>
      </ColumnOne>
      <ColumnTwo>
        <Title mb=".8">Pruébalo ahora</Title>
        <p>Entra y toquetea todo lo que quieras sin compromiso.</p>
        <ul>
            {[
              `Añade trabajadores`,
              `Mira el calendario de horas`,
              `Genera informes`

            ].map(list => (
              <li key={list}>
                <SVG icon="verified" fill="yellow" width="1.2" fixed/>
                {list}
              </li>
            ))}
          </ul>
        <DemoForm location={props.location}/>
      </ColumnTwo>
    </Box>
  </Wrapper>
  </>
)

export default Demostracion